import { useEffect, useState } from "react";
import axios from "axios";

const Rmfl = ({ apiUrl, fileName, actualPath }) => {
  const [responseMsg, setResponseMsg] = useState(null);

  useEffect(async () => {
    if (fileName.includes("\\")) fileName = fileName.replace("\\", " ");
    const requestInformations = {
      file_name: fileName,
      directory: actualPath
    };
    const response = await axios.post(
      `${apiUrl}/delete-file`,
      requestInformations
    );
    setResponseMsg(response.data);
  }, [fileName]);

  return (
    responseMsg !== null && (
      <div
        className="command-result"
        dangerouslySetInnerHTML={{ __html: `<p>${responseMsg}</p>` }}
      ></div>
    )
  );
};

export default Rmfl;
