import axios from "axios";
import { useEffect, useState } from "react";

const Cd = ({
  apiUrl,
  newDirectoryName,
  actualPath,
  setActualPath,
  setActualDirectoryName
}) => {
  const [responseMsg, setResponseMsg] = useState(null);

  useEffect(async () => {
    if (newDirectoryName !== "..") {
      if (newDirectoryName.includes("\\"))
        newDirectoryName = newDirectoryName.replace("\\", " ");

      const requestInformations = {
        directory: actualPath,
        directory_name: newDirectoryName
      };
      const response = await axios.post(
        `${apiUrl}/go-to-directory`,
        requestInformations
      );
      if (!response.data.includes("Aucun dossier ne se nomme")) {
        setActualPath(response.data);
        setActualDirectoryName(newDirectoryName);
        setResponseMsg(null);
      } else {
        setResponseMsg(response.data);
      }
    } else {
      const response = await axios.post(`${apiUrl}/go-to-previous-directory`, {
        directory: actualPath
      });
      setActualPath(response.data.new_directory);
      setActualDirectoryName(response.data.new_directory_name);
      setResponseMsg(null);
    }
  }, [newDirectoryName, actualPath]);

  return (
    responseMsg !== null && (
      <div
        className="command-result"
        dangerouslySetInnerHTML={{ __html: `<p>${responseMsg}</p>` }}
      ></div>
    )
  );
};

export default Cd;
