const Buttons = () => {
  return (
    <div className="buttons">
      <div className="button red"></div>
      <div className="button yellow"></div>
      <div className="button green"></div>
    </div>
  );
};

export default Buttons;
