import { useEffect, useState } from "react";
import axios from "axios";

const Rmfd = ({ apiUrl, folderName, actualPath }) => {
  const [responseMsg, setResponseMsg] = useState(null);

  useEffect(async () => {
    if (folderName.includes("\\")) folderName = folderName.replace("\\", " ");
    const requestInformations = {
      folder_name: folderName,
      directory: actualPath
    };
    const response = await axios.post(
      `${apiUrl}/delete-folder`,
      requestInformations
    );
    setResponseMsg(response.data);
  }, [folderName]);

  return (
    responseMsg !== null && (
      <div
        className="command-result"
        dangerouslySetInnerHTML={{ __html: `<p>${responseMsg}</p>` }}
      ></div>
    )
  );
};

export default Rmfd;
