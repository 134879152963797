import axios from "axios";
import { useEffect, useState } from "react";

const Ls = ({ apiUrl, actualPath }) => {
  const [contentDirectory, setContentDirectory] = useState(null);

  useEffect(async () => {
    const response = await axios.post([`${apiUrl}/list-content`], {
      directory: actualPath
    });
    setContentDirectory(response.data);
  }, []);

  return (
    <div className="command-result ls">
      <ul>
        {contentDirectory !== null &&
          contentDirectory.map((element) => {
            return <li key={element}>{element}</li>;
          })}
      </ul>
    </div>
  );
};

export default Ls;
