import Buttons from "./components/Buttons";
import Terminal from "./components/Terminal";
import HowToStart from "./components/HowToStart";

export default function App() {
  return (
    <>
      <HowToStart />
      <div className="App">
        <Buttons />
        <br />
        <Terminal />
      </div>
    </>
  );
}
