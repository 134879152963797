import { useEffect, useState } from "react";
import axios from "axios";

import Help from "../components/Commands/Help";
import NotExists from "../components/Commands/NotExists";
import Cd from "../components/Commands/Cd";
import Pwd from "../components/Commands/Pwd";
import Ls from "../components/Commands/Ls";
import Mkdir from "../components/Commands/Mkdir";
import Touch from "../components/Commands/Touch";
import Rmfl from "../components/Commands/Rmfl";
import Rmfd from "../components/Commands/Rmfd";

const Terminal = () => {
  const apiUrl = "http://localhost:5550/directory";
  const [commandComponent, setCommandComponent] = useState(null);
  const [actualPath, setActualPath] = useState(null);
  const [actualDirectoryName, setActualDirectoryName] = useState(null);
  const [computerName, setComputerName] = useState(null);

  useEffect(async () => {
    const response = await axios.get(`${apiUrl}/actual-directory`);
    setActualPath(response.data);
    const computerName = await axios.get(`${apiUrl}/computer-name`);
    setComputerName(computerName.data);
  }, []);
  const actualTime = () => {
    const date = new Date();
    const timeData = {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear()
    };
    return `${timeData.day}/${timeData.month}/${timeData.year}`;
  };
  const usersOS = () => {
    let os = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") !== -1) os = "Windows OS";
    if (navigator.userAgent.indexOf("Mac") !== -1) os = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") !== -1) os = "Linux OS";
    if (navigator.userAgent.indexOf("Android") !== -1) os = "Android OS";
    if (navigator.userAgent.indexOf("like Mac") !== -1) os = "iOS";
    return os;
  };
  const handleCommand = (e) => {
    e.preventDefault();
    let command = e.target["command"].value;

    if (command !== "") {
      const terms_in_command = command.split(" ");

      if (command === "help") {
        setCommandComponent(<Help />);
      } else if (terms_in_command[0] === "cd") {
        if (terms_in_command[1] !== undefined) {
          setCommandComponent(
            <Cd
              apiUrl={apiUrl}
              newDirectoryName={terms_in_command[1]}
              actualPath={actualPath}
              setActualPath={setActualPath}
              setActualDirectoryName={setActualDirectoryName}
            />
          );
        }
      } else if (command === "pwd") {
        setCommandComponent(<Pwd actualPath={actualPath} />);
      } else if (command === "clear") {
        setCommandComponent(null);
      } else if (command === "ls") {
        setCommandComponent(<Ls apiUrl={apiUrl} actualPath={actualPath} />);
      } else if (terms_in_command[0] === "mkdir") {
        if (terms_in_command[1] !== undefined) {
          setCommandComponent(
            <Mkdir
              apiUrl={apiUrl}
              folderName={terms_in_command[1]}
              actualPath={actualPath}
            />
          );
        }
      } else if (terms_in_command[0] === "touch") {
        if (terms_in_command[1] !== undefined) {
          setCommandComponent(
            <Touch
              apiUrl={apiUrl}
              fileName={terms_in_command[1]}
              actualPath={actualPath}
            />
          );
        }
      } else if (terms_in_command[0] === "rmfl") {
        if (terms_in_command[1] !== undefined) {
          setCommandComponent(
            <Rmfl
              apiUrl={apiUrl}
              fileName={terms_in_command[1]}
              actualPath={actualPath}
            />
          );
        }
      } else if (terms_in_command[0] === "rmfd") {
        if (terms_in_command[1] !== undefined) {
          setCommandComponent(
            <Rmfd
              apiUrl={apiUrl}
              folderName={terms_in_command[1]}
              actualPath={actualPath}
            />
          );
        }
      } else {
        setCommandComponent(<NotExists command={command} />);
      }

      e.target["command"].value = "";
    }
  };

  return (
    <div className="terminal-content">
      <p className="header">
        Connexion actuelle du {actualTime()} sur votre {usersOS()}, help pour
        voir la documentation.
      </p>
      <form onSubmit={handleCommand}>
        {/* Ici il faut modifier le label en fonction de la position où se trouve l'utilisateur dans un dossier */}
        <label>
          {computerName}:{actualDirectoryName} ${" "}
        </label>
        <input autoFocus type="text" name="command" autoComplete="off" />
      </form>
      {commandComponent !== null && commandComponent}
    </div>
  );
};

export default Terminal;
