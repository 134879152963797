const Pwd = ({ actualPath }) => {
  return (
    <div className="command-result">
      <p>
        Vous êtes dans le répertoire <b>{actualPath}</b>
      </p>
    </div>
  );
};

export default Pwd;
