import { useState } from "react";

const HowToStart = () => {
  const [showMessage, setShowMessage] = useState(true);
  const close = () => {
    setShowMessage(false);
  };

  return (
    showMessage && (
      <div className="HowToStart">
        <p>
          Attention, ce terminal est directement connecté à votre ordinateur.
          Pour pouvoir faire en sorte qu'il fonctionne, il est obligatoire de
          suivre les instructions suivantes :{" "}
          <a href="https://youtu.be/uSiphNxxYGs" target="_blank">
            https://youtu.be/uSiphNxxYGs
          </a>
        </p>
        <p onClick={close} className="close">
          Fermer
        </p>
      </div>
    )
  );
};

export default HowToStart;
