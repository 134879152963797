const Help = () => {
  return (
    <div className="command-result help">
      <ul>
        <li>
          <b>cd</b> - Aller dans un répertoire de votre ordinateur à partir du
          répertoire courant
        </li>
        <li>
          <b>pwd</b> - Afficher le chemin courant
        </li>
        <li>
          <b>ls</b> - Afficher tous les fichiers et dossiers du répertoire
          courant
        </li>
        <li>
          <b>mkdir</b> - Créer un dossier dans un répertoire
        </li>
        <li>
          <b>touch</b> - Créer un fichier dans un répertoire
        </li>
        <li>
          <b>rmfl</b> - Supprimer un fichier du répertoire courant
        </li>
        <li>
          <b>rmfd</b> - Supprimer un dossier du répertoire courant
        </li>
        <li>
          <b>clear</b> - Effacer le terminal
        </li>
        <li>
          Si le nom d'un dossier contient un espace, écrire <b>\</b> à la place
          de l'espace. Ex : <b>Cours JS devient Cours\JS</b>
        </li>
      </ul>
    </div>
  );
};

export default Help;
